import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ページが見つかりません" />
      <h1>ページが見つかりません</h1>
      <p>
        <Link to="/">← トップページ</Link>
      </p>
      <StaticImage
        className="image-404"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/404.jpg"
        layout="fullWidth"
        quality={95}
      />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
